import { Segmented } from "antd";
import React from "react";
import { LibraryArtistView } from "./library/artist";
import { LibraryAlbumView } from "./library/album";
import { LibrarySongView } from "./library/songs";

export function LibraryScreen() {
  const [screen, setScreen] = React.useState("artist");
  const options: any[] = [
    { label: "Artistes", value: "artist" },
    { label: "Sorties", value: "album" },
    { label: "Pistes", value: "music" },
  ];

  function handleChangeView(e: any) {
    setScreen(e);
  }

  return (
    <>
      <main className="main--admin">
        <Segmented options={options} onChange={handleChangeView} />
        {screen === "artist" && <LibraryArtistView />}
        {screen === "album" && <LibraryAlbumView />}
        {screen === "music" && <LibrarySongView />}
      </main>
    </>
  );
}
