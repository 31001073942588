import { Card, Divider, List, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toUpper } from "lodash";
import { CreateMusic } from "./createMusic";

const { Meta } = Card;
const { Paragraph } = Typography;

export function LibrarySongView() {
  const songs = useSelector((state: RootState) => state.song.all);
  const [open, setOpen] = React.useState(false);
  const [current, setCurrent] = React.useState<any>({});

  function handleOpen(item: any) {
    setCurrent(item);
    setOpen(true);
  }

  const renderItem = (item: any) => {
    return (
      <List.Item onClick={() => handleOpen(item)} style={{ cursor: "pointer" }}>
        <Card
          hoverable
          style={{ width: 240 }}
          cover={
            <img
              style={{ objectFit: "cover", height: 200 }}
              alt="example"
              src={item.cover}
            />
          }
        >
          <Meta
            title={toUpper(item.title)}
            description={
              <div className="audio">
                <audio controls src={item.song}></audio>
              </div>
            }
          />
          <Meta description={item?.artist?.artistName ?? ""} />
        </Card>
      </List.Item>
    );
  };
  return (
    <section>
      <Card>
        <CreateMusic />
      </Card>
      <Card>
        <Divider />
        <List
          dataSource={songs}
          renderItem={renderItem}
          grid={{ gutter: 16, lg: 3, xs: 1, md: 2, xxl: 4 }}
        />
      </Card>
    </section>
  );
}
