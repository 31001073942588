import { Button, Col, Divider, Drawer, Row, Switch, notification } from "antd";
import React from "react";
import { ImageFile } from "../../components/imageFile";
import { useFormik } from "formik";
import { DillInput, DillSelect } from "../../components/input";
import { useDispatch, useSelector } from "react-redux";
import songHelper from "../../utils/helpers/songHelper";
import * as yup from "yup";
import albumService from "../../services/albumService";
import { RootState } from "../../redux/store";
import artistService from "../../services/artistService";
import { isEmpty } from "lodash";

export function CreateAlbum() {
  const [open, setOpen] = React.useState(false);
  const [poster, setPoster] = React.useState<any>(null);
  const [thumb, setThumb] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const artists = useSelector((state: RootState) => state.artist.all);
  const [artistList, setArtistList] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      title: "",
      artist: "",
      year: "",
    },
    onSubmit,
    validationSchema: yup.object({
      title: yup.string().required("Champs requis"),
      artist: yup.string().required("Champs requis"),
    }),
  });

  const onInit = React.useCallback(() => {
    const elems: any[] = [];
    artists.forEach((p) => {
      elems.push({ title: p.artistName, value: p.uid });
    });
    elems.unshift({ title: "Sélectionner un artiste", value: "" });
    setArtistList(elems);
  }, [artists]);

  const onSearch = React.useCallback(async () => {
    if (search.length === 0) {
      const elems: any[] = [];
      artists.forEach((p) => {
        elems.push({ title: p.artistName, value: p.uid });
      });
      elems.unshift({ title: "Sélectionner un artiste", value: "" });
      setArtistList(elems);
      return;
    }
    setSearching(true);
    await artistService
      .getByKey(`artistName=${search}&order[artistName]=asc`)
      .then((response) => {
        const data = artistService.getData(response);
        const elems: any[] = [];
        data.forEach((p) => {
          elems.push({ title: p.artistName, value: p.uid });
        });
        elems.unshift({ title: "Sélectionner un artiste", value: "" });
        setArtistList(elems);
      })
      .catch(() => {});
    setSearching(false);
  }, [search]);

  React.useEffect(() => {
    onSearch();
  }, [search]);

  React.useEffect(() => {
    onInit();
  }, [artists]);

  async function onSubmit() {
    if (isEmpty(poster)) {
      notification.error({
        message: "Erreur sur l'image de fond",
        description: "Vous devez disposer d'une image de fond.",
      });
      return;
    }
    setLoading(true);
    const { artist, title, year } = formik.values;
    const data = new FormData();

    data.append("artist", artist);
    data.append("title", title);
    data.append("cover", poster);
    //data.append("thumb", thumbBlob);
    if (!isEmpty(year)) {
      data.append("year", year);
    }
    await albumService
      .store(data)
      .then(async () => {
        songHelper.getAllArtists("", dispatch);
        songHelper.getAllSongs("", dispatch);
        await songHelper.getAllAlbums("", dispatch);
        setOpen(false);
        formik.resetForm();
        setPoster(null);
        notification.info({
          message: "Enregistrement de l'album",
          description: "L'album a été ajouté avec succès",
        });
      })
      .catch(async (reason) => {
        const error = albumService.getError(reason);
        if (error.includes(`Unable to generate an IRI for the item of type`)) {
          songHelper.getAllArtists("", dispatch);
          songHelper.getAllSongs("", dispatch);
          await songHelper.getAllAlbums("", dispatch);
          setOpen(false);
          formik.resetForm();
          setPoster(null);
          notification.info({
            message: "Enregistrement de l'album",
            description: "L'album a été ajouté avec succès",
          });
        } else {
          notification.error({
            message: "Erreur de publication",
            description: error,
          });
        }
      });
    setLoading(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  return (
    <>
      <Button onClick={handleOpen}>{`Ajouter un nouvel album`}</Button>
      <Drawer
        title="Enregistrer une nouvelle sortie"
        onClose={handleClose}
        open={open}
        placement="right"
        width={600}
      >
        <Divider />
        <DillInput formik={formik} name="title" label="Titre de l'album *" />
        <DillInput formik={formik} name="year" label="Année de sortie" />
        <DillSelect
          formik={formik}
          name="artist"
          list={artistList}
          label="Artiste responsable de la sortie *"
          search
          setSearch={setSearch}
          loading={searching}
        />
        <div
          style={{
            margin: "auto",
            maxWidth: "calc( 100% - 10px)",
            //maxHeight: "350px",
            paddingBottom: 10,
          }}
        >
          <ImageFile
            type="image"
            file={poster}
            setFile={setPoster}
            setThumb={setThumb}
            description="Veuillez joindre votre image de fond"
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            {`Publier l'album`}
          </Button>
        </div>
      </Drawer>
    </>
  );
}
