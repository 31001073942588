import { isEmpty } from "lodash";
import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { SplashScreen } from "./splash";
import { FallbackScreen } from "./fallback";
import { BlockScreen } from "./block";
import { NotFoundScreen } from "./404";
import { FooterLayout } from "./footer";
import { FloatButton, Layout } from "antd";
import { getRoutePathOfComponent, routes } from "../routes";
import { NavBar } from "../components/navbar";
import { TopicMenu } from "../components/topicMenu";
import userHelper from "../utils/helpers/userHelper";
import { RootState } from "../redux/store";
import { SideBarComponent } from "../components/sidebar";
import { routeInterface } from "../utils/helpers/types";

export function AdminLayout() {
  const [items, setItems] = React.useState<routeInterface[]>(routes);
  const dispatch = useDispatch();
  const route = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [displayLocation, setDisplayLocation] = React.useState(route);
  const [transitionStage, setTransistionStage] = React.useState("fadeIn");
  const user = useSelector((state: RootState) => state.user.profil);
  //const url = useSelector((state: RootState) => state.user.floatUrl);
  const [contentIndex, setContentIndex] = React.useState(0);
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = React.useState("0");
  const [topics] = React.useState<any[]>(
    [...routes]
      ?.filter((p) => p.layout === "/admin" && p.isMenu)
      .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0)),
  );

  /*React.useEffect(() => {
    userHelper.initData(dispatch);
  }, []);*/

  /*React.useEffect(() => {
    init();
  }, [user]);*/

  /*async function init() {
    if (isEmpty(user)) {
      const element = await userHelper.getUserProfil();
      if (!isEmpty(element)) {
        await userHelper.setCurrentUser(element, dispatch);
      }
      if (isEmpty(element)) {
        window.location.href = "/";
      }
    }
  }*/

  /*React.useEffect(() => {
    if (route !== displayLocation) setTransistionStage("fadeOut");
    const index = routes.findIndex(
      (p) =>
        `${p.layout}${p.path}` === route.pathname ||
        `${p.layout}${p.path}/` === route.pathname
    );
    if (index != -1) {
      setAppTitle(routes[index]?.label);
    }
    dispatch(setFloatUrl(""));
  }, [route, displayLocation]);*/

  /*function onAnimation() {
    if (transitionStage === "fadeOut") {
      setTransistionStage("fadeIn");
      setDisplayLocation(route);
    }
  }*/

  /*function handleClick() {
    navigation(url ?? "");
  }*/

  const changeSelectedKey = (event: any) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
    const route = topics[key] ?? {};
    const link = getRoutePathOfComponent("", route.path);
    navigate(link);
  };
  const Menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  const onInit = React.useCallback(async () => {
    const profile = userHelper.getUserProfile();
    if (isEmpty(profile)) {
      window.location.href = "/home";
    }
    await userHelper.initData(dispatch);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    onInit();
    return () => {
      //userHelper.setLogout(dispatch);
    };
  }, []);

  return (
    <React.Fragment>
      <SplashScreen loading={loading} />
      <div className="App">
        <NavBar menu={Menu} />
        <Layout
          style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
        >
          <SideBarComponent menu={Menu} />
          <Layout.Content className="content">
            <Routes>
              {[...routes]
                .filter((p) => p.layout === "/admin")
                .map((p, index) => (
                  <Route
                    path={getRoutePathOfComponent("", p.path)}
                    element={<p.component />}
                  />
                ))}
            </Routes>
          </Layout.Content>
        </Layout>
      </div>
    </React.Fragment>
  );
}
