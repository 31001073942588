import { ThemeConfig } from "antd";
import { colorPrimary } from "./color";

const theme: ThemeConfig = {
  components: {
    Button: {
      fontSizeXL: 64,
      controlHeight: 40,
    },
    Input: {
      controlHeight: 40,
    },
    Segmented: {
      controlHeight: 40,
      itemSelectedBg: colorPrimary,
      itemSelectedColor: "#FFF",
    },
    Select: {
      controlHeight: 40,
    },
  },
  token: {
    colorPrimary: "#00BEF2",
  },
};

export { theme as lightTheme };
