import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface divisionData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: divisionData = {
  current: {},
  all: [],
  list: [],
};

export const divisionSlice = createSlice({
  name: "artist",
  initialState,
  reducers: {
    setArtist: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setArtists: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllArtists: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllArtists, setArtist, setArtists } = divisionSlice.actions;
export default divisionSlice.reducer;
