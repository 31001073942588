import {
  Button,
  Card,
  Divider,
  Typography,
  Upload,
  UploadProps,
  message,
} from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { getBase64 } from "../utils/helpers/title";

export function ImageFile(props: {
  file: any;
  setFile: any;
  type: "audio" | "image" | "video" | "pdf";
  description?: string;
  label?: string;
  help?: string;
  url?: string;
  setThumb?: any;
}) {
  const { Dragger } = Upload;
  const [url, setUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    accept: `${props.type}/*`,
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    showUploadList: false,
    className: "upload__container",
    /*beforeUpload(file, FileList) {
        return false;
    },*/
    async onChange(info) {
      const myFile = info.file;
      const { status } = myFile;
      await getBase64(info.file.originFileObj, (p) => {
        if (props.type === "image") {
          const img = new Image();
          img.src = p;
          const { naturalHeight, naturalWidth } = img;
          if (naturalHeight > 0 && naturalWidth > 0) {
            //const test1 = naturalHeight >= 400;
            //const test2 = naturalWidth >= 400;
            const test1 = true;
            const test2 = true;
            if (!(test1 && test2)) {
              message.error(
                `La taille de l'image doit être comprise entre 600 et 1280px. Vous avez utilisez (${naturalWidth}x${naturalHeight})`,
              );
            } else {
              if (typeof props.setThumb === "function") {
                img.width = naturalWidth * 0.2;
                img.height = naturalHeight * 0.2;
                //props.setThumb(img.src);
              }
              setUrl(p);
              props.setFile(myFile.originFileObj);
            }
          }
        } else {
          setUrl(p);
          props.setFile(myFile.originFileObj);
        }
      });
      if (status !== "uploading") {
        //console.log(info.file, info.fileList);
      }
      if (status === "done") {
        //message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        //message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onFileState = React.useCallback(() => {
    if (isEmpty(props.file)) {
      setUrl("");
    }
  }, [props.file]);
  React.useEffect(() => {
    onFileState();
  }, [props.file]);
  return (
    <>
      {props.type !== "audio" && (
        <Card
          className="card__1"
          bodyStyle={{
            maxHeight: "100%",
            overflowY: "hidden",
            borderBottom: 20,
          }}
        >
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <i></i>
            </p>
            <p className="ant-upload-text">
              {`Cliquez ou faites glisser le fichier vers cette zone pour le télécharger`}
            </p>
            <p className="ant-upload-hint">{props.description}</p>
            {!isEmpty(url) && (
              <div>
                {props.type === "image" && (
                  <div className="upload__img">
                    <img src={url} alt={props.description} />
                  </div>
                )}
                {/*props.type === "audio" && (
              <div className="upload__audio">
                <audio controls src={url}></audio>
              </div>
            )*/}
              </div>
            )}
          </Dragger>
        </Card>
      )}
      {props.type === "audio" && (
        <div>
          <Typography.Text>{props.label}</Typography.Text>
          <div className="upload__audio">
            <audio controls src={url}></audio>
          </div>
          <div style={{ textAlign: "center" }}>
            <Upload {...uploadProps}>
              <Button type="default" shape="round">
                {`Charger l'audio`}
              </Button>
            </Upload>
            <Divider />
          </div>
        </div>
      )}
    </>
  );
}
