import { Button, Col, Divider, Row, Select } from "antd";
import { FormikProps } from "formik";
import React from "react";
import { DillInput, DillSelect } from "./input";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export function ArtistChoice(props: {
  formik: FormikProps<any>;
  name: string;
  data: any[];
  placeholder?: string;
  label?: string;
}) {
  const artists = useSelector((state: RootState) => state.artist.all);
  const [searchValue, setSearchValue] = React.useState("");

  function handleChange(value: string[]) {
    props.formik.setFieldValue(props.name, value);
  }

  function onSearch(v: string) {
    console.log("SEARCH", v);
    setSearchValue(v);
  }

  return (
    <div
      style={{
        border: "solid 1px #3333",
        padding: "15px 10px",
        borderRadius: 8,
        position: "relative",
        marginTop: 15,
      }}
    >
      <div
        style={{
          position: "absolute",
          fontWeight: "bolder",
          top: -12,
          background: "#FFFFFF",
          padding: "2px 1px",
        }}
      >
        {props.label}
      </div>
      <Select
        style={{ width: "100%" }}
        allowClear
        title=""
        mode="multiple"
        options={props.data}
        showSearch
        filterOption
        optionFilterProp="label"
        virtual
        onChange={handleChange}
        placeholder={props.placeholder}
        searchValue={searchValue}
        labelInValue
        optionLabelProp="label"
        onSearch={onSearch}
      />
    </div>
  );
}
