import { Button, Card, Col, Row, Typography, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { DillInput, DillPassword } from "../components/input";
import userService from "../services/userService";
import userHelper from "../utils/helpers/userHelper";
import { useDispatch } from "react-redux";
import authService from "../services/authService";

export function LoginScreen() {
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit,
    validationSchema: yup.object({
      username: yup.string().required("Champ requis"),
      password: yup
        .string()
        .required("Champ requis")
        .min(8, "Minimun 8 caractères"),
    }),
  });
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  async function onSubmit() {
    const { password, username } = formik.values;
    const data: any = { email: username, password };
    setLoading(true);
    await userService
      .login(data)
      .then(async (response) => {
        const result = response.data;
        console.log("result", result);
        const token = result.token;
        authService.setToken(token);
        await userHelper.setCurrentUser(result.user, dispatch);
        await userHelper.initData(dispatch);
        window.location.href = "/";
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur d'authentification",
          description: error,
        });
        setLoading(false);
      });
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  return (
    <>
      <main className="main--login">
        {/* ts-ignore */}
        <video
          src={require("../assets/video/sound.mp4")}
          className="video"
          playsInline
          autoPlay
          loop
        ></video>
        <audio
          autoPlay
          muted
          playsInline
          src={require("../assets/audio/sunflower-street-drumloop-85bpm-163900.mp3")}
        ></audio>
        <Card className="card">
          <Typography.Title>KBOLA</Typography.Title>
          <div>
            <DillInput
              formik={formik}
              name="username"
              type="text"
              label="Adresse E-mail"
            />
            <DillPassword
              formik={formik}
              name="password"
              type="text"
              label="Mot de passe"
            />
          </div>
          <div className="input" style={{ paddingTop: 15 }}>
            <Button
              onClick={handleSubmit}
              loading={loading}
              disabled={loading}
              type="primary"
            >{`S'identifier`}</Button>
          </div>
          <Row
            gutter={32}
            align="middle"
            justify="space-between"
            style={{ marginTop: 10 }}
          >
            <Col flex={1}>
              <Button type="link">{`Mot de passe oublié ?`}</Button>
            </Col>
            <Col flex={1} style={{ textAlign: "right" }}>
              <Button
                href="/home/register"
                type="default"
              >{`S'inscrire`}</Button>
            </Col>
          </Row>
        </Card>
      </main>
      <div className="main--login-oreol">
        <img src={require("../assets/img/logo_oreol.png")} alt="" />
      </div>
    </>
  );
}
