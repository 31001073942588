import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface centerData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: centerData = {
  current: {},
  all: [],
  list: [],
};

export const centerSlice = createSlice({
  name: "songs",
  initialState,
  reducers: {
    setSong: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setSongs: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllSongs: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllSongs, setSong, setSongs } = centerSlice.actions;
export default centerSlice.reducer;
