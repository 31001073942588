import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/app.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider, Result } from "antd";
import store from "./redux/store";
import { lightTheme } from "./utils/themes/light";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        //renderEmpty={customizeRenderEmpty}
        theme={lightTheme}
        renderEmpty={() => (
          <div>
            {/*<Result
            status="404"
            title="Aucune donnée"
            subTitle="La liste de donnée est vide"
        />*/}
          </div>
        )}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
