import { upperCase, upperFirst } from "lodash";
import { setUserProfil } from "../../redux/userReducer";
import userService from "../../services/userService";
import songHelper from "./songHelper";

class UserHelper {
  private key: string = "@kbola_user";
  private setting_allow_sync_contacts: string =
    "@kbola_setting@allow_sync_contact";
  private setting_contact_sync_date: string =
    "@kbola_setting@contact_sync_date";

  async setCurrentUser(user: any, dispatch?: any) {
    let item: any = user;
    if (typeof dispatch === "function") {
      await userService
        .get(user.id)
        .then((response) => {
          item = response.data;
        })
        .catch(() => {});
      dispatch(setUserProfil(item));
    }
    localStorage.setItem(this.key, JSON.stringify(item));
    return item;
  }

  getUserName(user: any) {
    return `${upperFirst(user.firstName)} ${upperCase(user.lastName)}`;
  }

  async setLogout(dispatch?: any) {
    await userService
      .logout()
      .then(() => {})
      .catch((reason) => {
        console.log("reason", reason?.response ?? reason);
      });
    localStorage.removeItem(this.key);
    if (typeof dispatch === "function") {
      dispatch(setUserProfil({}));
    }
  }

  getUserProfile() {
    let item: any = localStorage.getItem(this.key);
    if (item != null) {
      const data = JSON.parse(item);
      return data;
    }
    return null;
  }

  async setUserSetting(
    type: "setting-contact-allow-sync" | "setting-contact-sync-date",
    value: any,
  ) {
    let elementKey = "";
    if (type === "setting-contact-allow-sync") {
      elementKey = this.setting_allow_sync_contacts;
    } else if (type === "setting-contact-sync-date") {
      elementKey = this.setting_contact_sync_date;
    }
    await localStorage.setItem(elementKey, JSON.stringify(value));
  }

  async getUserSetting(
    type: "setting-contact-allow-sync" | "setting-contact-sync-date",
  ) {
    let elementKey = "";
    if (type === "setting-contact-allow-sync") {
      elementKey = this.setting_allow_sync_contacts;
    } else if (type === "setting-contact-sync-date") {
      elementKey = this.setting_contact_sync_date;
    }
    let item: any = await localStorage.getItem(elementKey);
    if (item != null) {
      const data = JSON.parse(item);
      return data;
    }
    return null;
  }

  async initData(dispatch: any) {
    const user = await this.getUserProfile();
    await songHelper.getAllAlbums(user, dispatch);
    await songHelper.getAllArtists(user, dispatch);
    await songHelper.getAllSongs(user, dispatch);
    await songHelper.getAllCategories(dispatch);
  }
}
const userHelper = new UserHelper();
export default userHelper;
