import { Button, Col, Divider, Drawer, Row, Switch, notification } from "antd";
import React from "react";
import { ImageFile } from "../../components/imageFile";
import { useFormik } from "formik";
import { DillInput, DillSelect, DillTextarea } from "../../components/input";
import { useDispatch, useSelector } from "react-redux";
import songHelper from "../../utils/helpers/songHelper";
import * as yup from "yup";
import { RootState } from "../../redux/store";
import categoryService from "../../services/categoryService";
import { isEmpty } from "lodash";

export function CreateCategory() {
  const [open, setOpen] = React.useState(false);
  const [poster, setPoster] = React.useState<any>(null);
  const [thumb, setThumb] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const artists = useSelector((state: RootState) => state.artist.all);
  const [search, setSearch] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    onSubmit,
    validationSchema: yup.object({
      title: yup.string().required("Champs requis"),
    }),
  });

  async function onSubmit() {
    if (isEmpty(poster)) {
      notification.error({
        message: "Erreur sur l'image de fond",
        description: "Vous devez disposer d'une image de fond.",
      });
      return;
    }
    setLoading(true);
    const { description, title } = formik.values;
    const data = new FormData();

    data.append("title", title);
    data.append("cover", poster);
    data.append("description", description);
    await categoryService
      .store(data)
      .then(async () => {
        await songHelper.getAllCategories(dispatch);
        setOpen(false);
        formik.resetForm();
        setPoster(null);
        notification.info({
          message: "Enregistrement de la catégorie",
          description: "La catégorie a été ajouté avec succès",
        });
      })
      .catch(async (reason) => {
        const error = categoryService.getError(reason);
        if (error.includes(`Unable to generate an IRI for the item of type`)) {
          await songHelper.getAllCategories(dispatch);
          setOpen(false);
          formik.resetForm();
          setPoster(null);
          notification.info({
            message: "Enregistrement de la catégorie",
            description: "La catégorie a été ajouté avec succès",
          });
        } else {
          notification.error({
            message: "Erreur de publication",
            description: error,
          });
        }
      });
    setLoading(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  return (
    <>
      <Button onClick={handleOpen}>{`Ajouter une nouvelle catégorie`}</Button>
      <Drawer
        title="Enregistrer une nouvelle catégorie"
        onClose={handleClose}
        open={open}
        placement="right"
        width={600}
      >
        <Divider />
        <DillInput
          formik={formik}
          name="title"
          label="Titre de la catégorie *"
        />
        <div
          style={{
            margin: "auto",
            maxWidth: "calc( 100% - 10px)",
            //maxHeight: "350px",
            paddingBottom: 10,
          }}
        >
          <ImageFile
            type="image"
            file={poster}
            setFile={setPoster}
            setThumb={setThumb}
            description="Veuillez joindre votre image de fond"
          />
        </div>
        <DillTextarea
          formik={formik}
          name="description"
          label="Déscription de la catégorie"
        />
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            {`Publier la catégorie`}
          </Button>
        </div>
      </Drawer>
    </>
  );
}
