import { Spin, Typography } from "antd";
import React from "react";

export function SplashScreen(props: { loading: boolean }) {
  const onLoading = React.useCallback(() => {
    if (props.loading) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "unset";
    }
  }, [props.loading]);

  React.useEffect(() => {
    onLoading();
  }, [props.loading]);

  return (
    <React.Fragment>
      {props.loading && (
        <div className="splash--screen">
          <Spin size="large" fullscreen={false} />
          <Typography.Text style={{ color: "#FFF" }} strong>
            {`Chargement...`}
          </Typography.Text>
        </div>
      )}
    </React.Fragment>
  );
}
