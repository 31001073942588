import { Button, Col, Divider, Drawer, Row, Switch, notification } from "antd";
import React from "react";
import { ImageFile } from "../../components/imageFile";
import { useFormik } from "formik";
import { DillInput, DillTextarea } from "../../components/input";
import { useDispatch } from "react-redux";
import songHelper from "../../utils/helpers/songHelper";
import * as yup from "yup";
import artistService from "../../services/artistService";
import { isEmpty } from "lodash";

export function CreateArtist() {
  const [open, setOpen] = React.useState(false);
  const [poster, setPoster] = React.useState<any>(null);
  const [thumb, setThumb] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      artistName: "",
      sex: "f",
      bornDate: "",
      biography: "",
    },
    onSubmit,
    validationSchema: yup.object({
      artistName: yup.string().required("Champ requis"),
    }),
  });

  async function onSubmit() {
    const { artistName, bornDate, firstName, lastName, sex } = formik.values;
    if (isEmpty(poster)) {
      notification.error({
        message: "Champ manquant",
        description: "Veuillez ajouter la photo de l'artiste",
      });
      return;
    }
    setLoading(true);
    const data = new FormData();

    data.append("artistName", artistName);
    data.append("firstName", firstName);
    data.append("cover", poster);
    data.append("lastName", lastName);
    data.append("sex", sex);
    data.append("birthAt", bornDate);
    await artistService
      .store(data)
      .then(async () => {
        await songHelper.getAllArtists("", dispatch);
        setOpen(false);
        formik.resetForm();
        setPoster(null);
        notification.info({
          message: "Enregistrement de l'artiste",
          description: "L'artiste a été ajouté avec succès",
        });
      })
      .catch(async (reason) => {
        const error = artistService.getError(reason);
        if (error.includes(`Unable to generate an IRI for the item of type`)) {
          await songHelper.getAllArtists("", dispatch);
          setOpen(false);
          formik.resetForm();
          setPoster(null);
          notification.info({
            message: "Enregistrement de l'artiste",
            description: "L'artiste a été ajouté avec succès",
          });
        } else {
          notification.error({
            message: "Erreur de publication",
            description: error,
          });
        }
      });
    setLoading(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  return (
    <>
      <Button
        type="primary"
        onClick={handleOpen}
      >{`Publier un nouvel artiste`}</Button>
      <Drawer
        title="Enregistrer un nouvel artiste"
        onClose={handleClose}
        open={open}
        placement="right"
        width={600}
      >
        <Divider />
        <DillInput formik={formik} name="lastName" label="Nom de l'artiste" />
        <DillInput
          formik={formik}
          name="firstName"
          label="Prénom de l'artiste"
        />
        <DillInput formik={formik} name="artistName" label="Nom d'artiste *" />
        <DillInput
          formik={formik}
          name="bornDate"
          type="date"
          label="Date de naissance"
        />
        <div
          style={{
            margin: "auto",
            maxWidth: "calc( 100% - 10px)",
            //maxHeight: "300px",
            paddingBottom: 10,
          }}
        >
          <ImageFile
            type="image"
            file={poster}
            setFile={setPoster}
            setThumb={setThumb}
            description="Veuillez joindre votre image de fond"
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            {`Publier l'artiste`}
          </Button>
        </div>
      </Drawer>
    </>
  );
}
