import { Card, List, Segmented, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { toUpper } from "lodash";
import Meta from "antd/es/card/Meta";
import { CreateCategory } from "./category/createCategory";

export function CategoryScreen() {
  const categories = useSelector((state: RootState) => state.category.all);

  const renderItem = (item: any) => {
    return (
      <List.Item onClick={() => handleOpen(item)} style={{ cursor: "pointer" }}>
        <Card
          hoverable
          style={{ width: 240 }}
          cover={
            <img
              style={{ objectFit: "cover", height: 200 }}
              alt="example"
              src={item.photo}
            />
          }
        >
          <Meta description={toUpper(item?.title)} />
        </Card>
      </List.Item>
    );
  };

  function handleOpen(item: any) {
    //setCurrent(item);
    //setOpen(true);
  }

  return (
    <>
      <main className="main--admin">
        <Card style={{ marginBottom: 10 }}>
          <CreateCategory />
        </Card>
        <List
          grid={{ gutter: 16, lg: 3, xs: 1, md: 2, xxl: 4 }}
          dataSource={categories}
          renderItem={renderItem}
        />
      </main>
    </>
  );
}
