import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Card, Divider, List } from "antd";
import { toUpper } from "lodash";
import { CreateAlbum } from "./createAlbum";
import { AlbumShow } from "./openAlbum";

const { Meta } = Card;

export function LibraryAlbumView() {
  const albums = useSelector((state: RootState) => state.album.all);
  const [open, setOpen] = React.useState(false);
  const [current, setCurrent] = React.useState<any>({});

  const renderItem = (item: any) => {
    return (
      <List.Item onClick={() => handleOpen(item)} style={{ cursor: "pointer" }}>
        <Card
          hoverable
          style={{ width: 240 }}
          cover={
            <img
              style={{ objectFit: "cover", height: 200 }}
              alt="example"
              src={item.cover}
            />
          }
        >
          <Meta description={toUpper(item?.title)} />
        </Card>
      </List.Item>
    );
  };

  function handleOpen(item: any) {
    setCurrent(item);
    setOpen(true);
  }
  return (
    <section>
      <Card>
        <CreateAlbum />
        <Divider />
        <List
          grid={{ gutter: 16, lg: 3, xs: 1, md: 2, xxl: 4 }}
          dataSource={albums}
          renderItem={renderItem}
        />
      </Card>
      <AlbumShow item={current} open={open} setOpen={setOpen} />
    </section>
  );
}
