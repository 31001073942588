import { Button, Col, Divider, Drawer, Row, Switch, notification } from "antd";
import React from "react";
import { ImageFile } from "../../components/imageFile";
import { useFormik } from "formik";
import {
  DillInput,
  DillRadio,
  DillSelect,
  DillSwitch,
  DillTextarea,
} from "../../components/input";
import { ArtistChoice } from "../../components/artists";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import songService from "../../services/songService";
import songHelper from "../../utils/helpers/songHelper";
import { isEmpty, toNumber, toString } from "lodash";
import * as yup from "yup";
import albumService from "../../services/albumService";

export function CreateMusic() {
  const [open, setOpen] = React.useState(false);
  const [loadAlbum, setLodAlbum] = React.useState(false);
  const [audio, setAudio] = React.useState<any>(null);
  const [poster, setPoster] = React.useState<any>(null);
  const artists = useSelector((state: RootState) => state.artist.all);
  const categories = useSelector((state: RootState) => state.category.all);
  const [artistList, setArtistList] = React.useState<any[]>([]);
  const [categoryList, setCategoryList] = React.useState<any[]>([]);
  const [albums, setAlbums] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      title: "",
      subtitle: "",
      description: "",
      artist: "",
      collaborations: [],
      album: "",
      typeMedia: "music",
      isInstrumentalOnly: false,
      isPodcast: false,
      isSound: false,
      isRemix: false,
      isMusic: true,
      remixTitle: "",
      categories: [],
    },
    onSubmit,
    validationSchema: yup.object({
      title: yup.string().required("Champ requis"),
      artist: yup.string().required("Champ requis"),
    }),
  });

  async function onSubmit() {
    if (isEmpty(poster)) {
      notification.error({
        message: "Erreur sur l'image de fond",
        description: "Vous devez disposer d'une image de fond.",
      });
      return;
    }
    if (isEmpty(audio)) {
      notification.error({
        message: "Erreur sur le contenu musical",
        description: "Vous devez disposer d'un fichier audio pour publier.",
      });
      return;
    }
    setLoading(true);
    const { artist, title, isInstrumentalOnly, album, collaborations } =
      formik.values;
    const data = new FormData();
    data.append("principalArtist", artist);
    if (!isEmpty(album)) {
      data.append("album", album);
    }
    const cats = formik.values.categories.map((p: any) => p.value);
    data.append("title", title);
    data.append("cover", poster);
    data.append("song", audio);
    data.append("isInstrumentalOnly", toString(isInstrumentalOnly === true));
    //data.append("isMusic", "");
    //data.append("isPodcast", "");
    //data.append("isRemix", false);
    //data.append("isSound", "");
    //data.append("remixTitle", "");
    data.append("categories", JSON.stringify(cats));
    data.append(
      "collaborations",
      JSON.stringify(collaborations.map((p: any) => p.value)),
    );
    await songService
      .store(data)
      .then(async () => {
        await songHelper.getAllSongs("", dispatch);
        setOpen(false);
        formik.resetForm();
        setPoster(null);
        setAudio(null);
        setArtistList([]);
        notification.info({
          message: "Publication de la musique",
          description: "La musique a été publiée avec succès",
        });
      })
      .catch(async (reason) => {
        const error = songService.getError(reason);
        if (error.includes(`Unable to generate an IRI for the item of type`)) {
          await songHelper.getAllSongs("", dispatch);
          setOpen(false);
          formik.resetForm();
          setPoster(null);
          setAudio(null);
          setArtistList([]);
          notification.info({
            message: "Publication de la musique",
            description: "La musique a été publiée avec succès",
          });
        } else {
          notification.error({
            message: "Erreur de publication",
            description: error,
          });
        }
      });
    setLoading(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  React.useEffect(() => {
    function onArtist() {
      const list = artists.map((p: any) => {
        return { value: p.uid, title: p.artistName, label: p.artistName };
      });
      //list.unshift({ value: "", title: "Séléctionner un artiste",label: "Séléctionner un artiste" });
      setArtistList(list);
    }
    function onCategory() {
      const list = categories.map((p: any) => {
        return { value: p.uid, title: p.title, label: p.title };
      });
      //list.unshift({ value: "", title: "Séléctionner une catégorie",label:"Séléctionner une catégorie" });
      setCategoryList(list);
    }
    onArtist();
    onCategory();
  }, []);

  React.useEffect(() => {
    onSearchAlbum();
  }, [formik.values.artist]);

  const onSearchAlbum = React.useCallback(async () => {
    const id = formik.values.artist;
    const elements: any[] = [];
    setAlbums([]);
    formik.setFieldValue("album", "");
    if (!isEmpty(id)) {
      setLodAlbum(true);
      await albumService
        .getByKey(`artist=${id}`)
        .then((response) => {
          const data = albumService.getData(response);
          data.forEach((p) => {
            elements.push({
              title: p.title,
              value: p.uid,
            });
          });
        })
        .catch(() => {});
      setLodAlbum(false);
    }
    elements.unshift({ value: "", title: "Sélectionner l'album" });
    setAlbums(elements);
  }, [formik.values.artist]);

  return (
    <>
      <Button
        type="primary"
        shape="round"
        onClick={handleOpen}
      >{`Ajouter une nouvelle musique`}</Button>
      <Drawer
        title="Enregistrer un nouveau son"
        onClose={handleClose}
        open={open}
        placement="right"
        width={600}
        //contentWrapperStyle={{ height: "100vh" }}
      >
        <DillSwitch
          formik={formik}
          label="Instrumental"
          name="isInstrumentalOnly"
        />
        <DillSwitch formik={formik} label="Remix" name="isRemix" />
        <Divider />
        <div style={{ margin: "auto", maxWidth: "calc( 100% - 10px)" }}>
          <ImageFile
            type="audio"
            file={audio}
            setFile={setAudio}
            description="Veuillez joindre votre son "
            label="Fichier audio en format .mp3 ou .wav"
          />
        </div>
        <DillInput formik={formik} name="title" label="Titre de la chanson" />
        <DillSelect
          formik={formik}
          list={artistList}
          name="artist"
          label="Artiste principal"
        />
        <DillSelect
          formik={formik}
          list={albums}
          name="album"
          label="Album"
          loading={loadAlbum}
          enabled={albums.length > 1}
        />
        <ArtistChoice
          placeholder="Sélectionner des artistes en collaboration"
          data={artistList}
          formik={formik}
          name="collaborations"
          label="Collaborations"
        />
        <ArtistChoice
          placeholder="Catégories"
          data={categoryList}
          formik={formik}
          name="categories"
          label="Catégories"
        />
        <DillInput
          formik={formik}
          name="remixTitle"
          enabled={formik.values.isRemix}
          label="Titre du remix"
        />
        <div
          style={{
            margin: "auto",
            maxWidth: "calc( 100% - 10px)",
            //maxHeight: "300px",
            paddingBottom: 10,
          }}
        >
          <ImageFile
            type="image"
            file={poster}
            setFile={setPoster}
            description="Veuillez joindre votre image de fond"
          />
        </div>
        <div>
          <DillTextarea
            formik={formik}
            name="description"
            label="Description de la chanson"
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            {`Publier la musique`}
          </Button>
        </div>
      </Drawer>
    </>
  );
}
