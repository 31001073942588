import React from "react";
import { Layout } from "antd";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  MenuItemStyles,
} from "react-pro-sidebar";
import { Theme, layoutType, routeInterface } from "../utils/helpers/types";
import { hexToRgba } from "../utils/themes/color";
import { SidebarHeader } from "./sidebar/sidebarHeader";
import { Typography } from "./sidebar/Typography";
import { SidebarFooter } from "./sidebar/sidebarFooter";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { getRoutePathOfComponent, routes } from "../routes";

const SideBarComponent = (props: { menu: any }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [rtl, setRtl] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(false);
  const [theme, setTheme] = React.useState<Theme>("light");
  const [dataSource, setDataSource] = React.useState<routeInterface[]>([]);

  const onInit = React.useCallback(() => {
    const list = routes.filter((p) => p.isMenu && p.layout === "/admin");
    console.log("LIST", list);
    setDataSource(list);
  }, [routes]);

  React.useEffect(() => {
    onInit();
  }, [routes]);

  const themes = {
    light: {
      sidebar: {
        backgroundColor: "#ffffff",
        color: "#607489",
      },
      menu: {
        menuContent: "#fbfcfd",
        icon: "#0098e5",
        hover: {
          backgroundColor: "#c5e4ff",
          color: "#44596e",
        },
        disabled: {
          color: "#9fb6cf",
        },
      },
    },
    dark: {
      sidebar: {
        backgroundColor: "#0b2948",
        color: "#8ba1b7",
      },
      menu: {
        menuContent: "#082440",
        icon: "#59d0ff",
        hover: {
          backgroundColor: "#00458b",
          color: "#b6c8d9",
        },
        disabled: {
          color: "#3e5e7e",
        },
      },
    },
  };

  // handle on RTL change event
  const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRtl(e.target.checked);
  };

  // handle on theme change event
  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme(e.target.checked ? "dark" : "light");
  };

  // handle on image change event
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasImage(e.target.checked);
  };

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: (level: any) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1,
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1,
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: (open: any) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    /*<Layout.Sider
      className="sidebar"
      breakpoint={"lg"}
      theme="light"
      collapsedWidth={0}
      trigger={null}
    >
      {props.menu}
    </Layout.Sider>*/
    <div
      style={{
        display: "flex",
        height: `92vh`,
        position: "sticky",
        left: 0,
        top: "8vh",
        width: 250,
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
        rtl={rtl}
        breakPoint="md"
        backgroundColor={hexToRgba(
          themes[theme].sidebar.backgroundColor,
          hasImage ? 0.9 : 1,
        )}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <SidebarHeader
            rtl={rtl}
            style={{ marginBottom: "24px", marginTop: "16px" }}
          />
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
              >
                General
              </Typography>
            </div>
            <Menu menuItemStyles={menuItemStyles}>
              {dataSource.map((p, i) => {
                console.log("DDD", p);
                return <RenderMenu menu={p} key={`menu_${i}`} />;
              })}
            </Menu>
          </div>
          <SidebarFooter collapsed={collapsed} />
        </div>
      </Sidebar>
    </div>
  );
};

const RenderMenu: React.FC<{ menu: routeInterface }> = ({ menu }) => {
  function getPath(layout: layoutType, path: string) {
    const v = getRoutePathOfComponent(layout, path);
    return v;
  }

  return (
    <>
      {/*@ts-ignore*/}
      {menu.children?.length > 0 && (
        <SubMenu label={menu.label}>
          {menu.children?.map((p, i) => (
            <RenderMenu menu={p} key={`submenu_${i}`} />
          ))}
        </SubMenu>
      )}
      {(isEmpty(menu.children) || menu.children?.length === 0) && (
        <MenuItem component={<Link to={getPath(menu.layout, menu.path)} />}>
          {menu.label}
        </MenuItem>
      )}
    </>
  );
};

export { SideBarComponent };
