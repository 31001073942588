import { isEmpty } from "lodash";
import { LibraryScreen } from "./screens/library";
import { LoginScreen } from "./screens/login";
import { RegisterScreen } from "./screens/register";
import { layoutType, routeInterface } from "./utils/helpers/types";
import { CategoryScreen } from "./screens/category";

export function getRoutePathOfComponent(
  layout: layoutType | string,
  path: string,
) {
  let result: string = ``;
  if (layout !== "/admin") {
    result = `${layout}`;
  }
  if (isEmpty(layout)) {
    result = "";
  } else if ((layout[0] ?? "" ?? "") !== "/") {
    result = "/" + result;
  }
  if ((path[0] ?? "" ?? "") !== "/") {
    result += "/" + path;
  } else {
    result += path;
  }
  return result;
}

const routes: routeInterface[] = [
  {
    name: "login",
    label: "",
    path: "/",
    layout: "/home",
    isMenu: false,
    component: LoginScreen,
  },
  {
    name: "register",
    label: "",
    path: "/register",
    layout: "/home",
    isMenu: false,
    component: RegisterScreen,
  },
  {
    name: "library",
    label: "Bibliothèques",
    path: "/",
    layout: "/admin",
    isMenu: true,
    component: LibraryScreen,
  },
  {
    name: "category",
    label: "Catégories",
    path: "/categories",
    layout: "/admin",
    isMenu: true,
    component: CategoryScreen,
  },
];

export { routes };
