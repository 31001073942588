import { Axios, AxiosResponse } from "axios";
import http, { https } from "./apiConfig";
import { isArray, isEmpty, isString } from "lodash";

export default abstract class Crud {
  abstract getAll(): any;
  abstract get(id: number | string): Promise<AxiosResponse>; // eslint-disable-line no-unused-vars
  abstract getByKey(query: string): Promise<AxiosResponse>; // eslint-disable-line no-unused-vars
  abstract store(data: object | any): Promise<AxiosResponse>; // eslint-disable-line no-unused-vars
  abstract update(
    id: number | string, // eslint-disable-line no-unused-vars
    data: object | any, // eslint-disable-line no-unused-vars
  ): Promise<AxiosResponse>;
  abstract destroy(id: number | string): Promise<AxiosResponse>; // eslint-disable-line no-unused-vars
  abstract url: string;
  protected axios: Axios = http;
  protected secure: Axios = https;
  public getData(response: AxiosResponse<any, any>): [] | any[] {
    const data = response.data;
    let result: any[] = [];
    if (isArray(data)) {
      result = data;
    } else if (typeof data === "object") {
      result = data["hydra:member"] ?? [];
    } else if (typeof response.data !== "object") {
      result = [];
    }
    return result;
  }
  public getTotal(response: AxiosResponse<any, any>): number {
    if (typeof response.data !== "object") {
      return 0;
    }
    return response.data["hydra:totalItems"];
  }
  public getError(reason: any): string {
    if (typeof reason?.response !== "object") {
      return "";
    }
    const errors = reason?.response?.data?.error ?? {};
    let message = "";
    for (const key in errors) {
      message += `${key} : `;
      const element: any = errors[key] as any;
      if (isArray(element)) {
        element?.forEach((p) => {
          message += `${p},`;
        });
      } else if (isString(element)) {
        message += element;
      }
    }
    if (!isEmpty(message)) {
      return message;
    }
    const data = reason?.response?.data;
    if (typeof data !== "object") {
      return "";
    }
    return data["hydra:description"] ?? "";
  }
}
