import { Avatar, Drawer, Typography } from "antd";
import React from "react";
import userHelper from "../../utils/helpers/userHelper";

export function ArtistShow(props: {
  item: any;
  open: boolean;
  setOpen: (v: boolean) => void;
}) {
  return (
    <>
      <Drawer
        placement="right"
        open={props.open}
        title={userHelper.getUserName(props.item)}
        onClose={() => props.setOpen(false)}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            <Avatar src={props.item.thumb} size={100} />
          </div>
          <div style={{ textAlign: "center", textTransform: "uppercase" }}>
            <Typography.Text strong>{props.item.artist_name}</Typography.Text>
          </div>
        </div>
      </Drawer>
    </>
  );
}
