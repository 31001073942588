import React from "react";
import "antd/dist/reset.css";
import { Route, Routes } from "react-router-dom";
import { HomeLayout } from "./layouts/home";
import { AdminLayout } from "./layouts/admin";
import { NotFoundScreen } from "./layouts/404";

function App() {
  return (
    <Routes>
      <Route element={<HomeLayout />} path="home/*" />
      <Route element={<AdminLayout />} path="/*" />
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
}

export default App;
