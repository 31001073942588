import { Button, Card, Col, Row, Typography, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { DillInput, DillPassword } from "../components/input";
import userService from "../services/userService";
import authService from "../services/authService";
import userHelper from "../utils/helpers/userHelper";
import { useDispatch } from "react-redux";
import { isArray, isString } from "lodash";
import { useNavigate } from "react-router-dom";

export function RegisterScreen() {
  const formik = useFormik({
    initialValues: {
      password: "",
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
    },
    onSubmit,
    validationSchema: yup.object({
      lastName: yup.string().required("Champ requis"),
      firstName: yup.string().required("Champ requis"),
      password: yup
        .string()
        .required("Champ requis")
        .min(8, "Minimun 8 caractères"),
    }),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  async function onSubmit() {
    const { email, firstName, lastName, password, phone } = formik.values;

    const data: any = {
      email,
      password,
      firstName,
      lastName,
      phone,
    };
    setLoading(true);
    await userService
      .store(data)
      .then(async () => {
        //const result = response.data;
        //const token = result?.token;
        //authService.setToken(token);
        //await userHelper.setCurrentUser(result, dispatch);
        notification.info({
          message: "Inscription",
          description: `Votre inscription dans l'espace KBOLA a été validé`,
        });
        navigate("/home/");
        //window.location.href = "/home/login";
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur d'inscription",
          description: error,
        });
        setLoading(false);
      });
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  return (
    <>
      <main className="main--login">
        {/* ts-ignore */}
        <video
          src={require("../assets/video/sound.mp4")}
          className="video"
          playsInline
          autoPlay
          loop
        ></video>
        <audio
          autoPlay
          playsInline
          loop
          muted
          src={require("../assets/audio/sunflower-street-drumloop-85bpm-163900.mp3")}
        ></audio>
        <Card className="card">
          <Typography.Title>KBOLA</Typography.Title>
          <div>
            <DillInput
              formik={formik}
              name="firstName"
              type="text"
              label="Votre prénom"
            />
            <DillInput
              formik={formik}
              name="lastName"
              type="text"
              label="Votre nom de famille"
            />
            <DillInput
              formik={formik}
              name="email"
              type="email"
              label="Adresse E-mail"
            />
            <DillInput
              formik={formik}
              name="phone"
              type="tel"
              label="Numéro de téléphone"
            />
            <DillPassword
              formik={formik}
              name="password"
              type="text"
              label="Mot de passe"
            />
          </div>
          <div className="input" style={{ paddingTop: 15 }}>
            <Button
              onClick={handleSubmit}
              loading={loading}
              disabled={loading}
              type="primary"
            >{`S'inscrire`}</Button>
          </div>
          <Row
            gutter={32}
            align="middle"
            justify="space-between"
            style={{ marginTop: 10 }}
          >
            <Col flex={1}>
              <Button
                type="link"
                href="/home/"
              >{`Vous avez un compte ? Se connecter.`}</Button>
            </Col>
          </Row>
        </Card>
      </main>
      <div className="main--login-oreol">
        <img src={require("../assets/img/logo_oreol.png")} alt="" />
      </div>
    </>
  );
}
