import Crud from "./crud";
class AlbumService extends Crud {
  url = "albums";
  destroy(id: string | number) {
    return this.secure.delete(`${this.url}/${id}`);
  }
  get(id: string | number) {
    return this.secure.get(`${this.url}/${id}`);
  }
  getAll() {
    return this.secure.get(`${this.url}`);
  }
  getByKey(query: string) {
    return this.secure.get(`${this.url}?${query}`);
  }
  store(data: any) {
    return this.secure.post(`${this.url}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  update(id: string | number, data: any) {
    return this.secure.put(`${this.url}/${id}`, data);
  }
}

export default new AlbumService();
