import http from "./authConfig";

const TOKEN = "arka_token";
const REFRESH_TOKEN = "arka_refresh_token";

class AuthApi {
  async refreshToken() {
    const refresh_token = await this.getRefreshToken();
    let rep = false;
    if (refresh_token) {
      await http
        .post("/auth/refresh", { refresh_token })
        .then((response) => response.data)
        .then((response) => {
          if (response.token) {
            rep = true;
            localStorage.setItem(TOKEN, response.token);
            localStorage.setItem(REFRESH_TOKEN, response.refresh_token);
          }
        })
        .catch((e) => console.warn("Error", e));
    }
    return rep;
  }

  async logout() {
    await localStorage.removeItem(TOKEN);
    await localStorage.removeItem(REFRESH_TOKEN);
  }

  getToken() {
    return localStorage.getItem(TOKEN);
  }

  setToken(value: string) {
    return localStorage.setItem(TOKEN, value);
  }

  async getRefreshToken() {
    return await localStorage.getItem(REFRESH_TOKEN);
  }
}

export default new AuthApi();
