import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface leanerData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: leanerData = {
  current: {},
  all: [],
  list: [],
};

export const leanerSlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setCategories: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllCategories: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllCategories, setCategories, setCategory } =
  leanerSlice.actions;
export default leanerSlice.reducer;
