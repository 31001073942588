import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface bookData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: bookData = {
  current: {},
  all: [],
  list: [],
};

export const bookSlice = createSlice({
  name: "album",
  initialState,
  reducers: {
    setAlbum: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setAlbums: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllAlbums: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAlbum, setAlbums, setAllAlbums } = bookSlice.actions;
export default bookSlice.reducer;
