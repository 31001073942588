import { Card, Input, Radio, Switch, Typography } from "antd";
import React from "react";
import { ErrorAlert } from "../error";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

const { Text } = Typography;

export default function App(props: {
  label: string;
  id?: string;
  name: string;
  formik: any;
  enabled?: boolean;
}) {
  const { enabled = true, formik, label, name, id } = props;

  function handleChange(v: boolean) {
    formik?.setFieldValue(name, v);
  }
  return (
    <Card bodyStyle={{ padding: "10px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1 }}>
          <Typography.Text>{label}</Typography.Text>
        </div>
        <div>
          <Switch
            id={!isEmpty(id) ? id : undefined}
            disabled={!enabled}
            onChange={handleChange}
            value={formik.values?.[name] ?? false}
          />
        </div>
      </div>
      {formik.errors[name] && formik.touched[name] && (
        <ErrorAlert message={formik.errors[name]} />
      )}
    </Card>
  );
}
