import React, { useState } from "react";
import {
  Drawer,
  Button,
  Flex,
  Modal,
  Dropdown,
  notification,
  Typography,
} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../assets/img/logo.png";
import userHelper from "../utils/helpers/userHelper";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { RootState } from "../redux/store";

const NavBar = (props: { menu: any }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.profil);

  function handleLogout() {
    Modal.confirm({
      title: "Déconnexion",
      content: "Voulez-vous vous déconnecter ?",
      okText: "Se déconnecter",
      cancelText: "Annuler",
      okButtonProps: { danger: true, type: "primary" },
      cancelButtonProps: { type: "default" },
      onOk: onLogout,
    });
  }

  async function onLogout() {
    setLoading(true);
    await userHelper.setLogout(dispatch);
    window.location.href = "/home";
  }
  return (
    <nav
      className="navbar"
      style={{ display: "flex", alignItems: "center", padding: "10px" }}
    >
      <Button
        className="menu"
        type="text"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      />
      <Drawer
        title=""
        placement="left"
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        {props.menu}
      </Drawer>
      <Link to="/" className="logo__component">
        <img src={logo} className="logo" alt="logo" />
        <span className="title">KBOLA</span>
      </Link>
      <div style={{ flex: 1 }}></div>
      <Typography.Text type="success" style={{ paddingRight: 5 }}>
        {userHelper.getUserName(user)}
      </Typography.Text>
      <div>
        <Dropdown
          menu={{
            items: [
              {
                label: "Mon profile",
                key: "profile",
                onClick: () => {
                  notification.info({
                    message: "INFO",
                    description: "Action non disponible",
                  });
                },
              },
              { type: "divider" },
              {
                label: "Déconnexion",
                key: "logout",
                onClick: handleLogout,
                type: "item",
                danger: true,
              },
            ],
          }}
          trigger={["click"]}
          arrow
        >
          <Button shape="circle" size="large" type="primary">
            <Icon icon="ic:baseline-person" style={{ fontSize: 20 }} />
          </Button>
        </Dropdown>
      </div>
    </nav>
  );
};
export { NavBar };
