import { setAllAlbums } from "../../redux/albumReducer";
import { setAllArtists } from "../../redux/artistReducer";
import { setAllCategories } from "../../redux/categoryReducer";
import { setAllSongs } from "../../redux/songReducer";
import albumService from "../../services/albumService";
import artistService from "../../services/artistService";
import categoryService from "../../services/categoryService";
import songService from "../../services/songService";

class SongHelper {
  async getAllSongs(user: any, dispatch?: any) {
    let items: any[] = [];
    await songService
      .getByKey(``)
      .then((response) => {
        items = songService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllSongs(items));
        }
      })
      .catch((reason) => {
        console.log("Reason", reason);
      });
    return items;
  }

  async getAllCategories(dispatch?: any) {
    let items: any[] = [];
    await categoryService
      .getByKey(`order[title]=asc`)
      .then((response) => {
        items = categoryService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllCategories(items));
        }
      })
      .catch((reason) => {
        console.log("Reason", reason);
      });
    return items;
  }

  async getAllAlbums(user: any, dispatch?: any) {
    let items: any[] = [];

    //if (!isEmpty(user)) {
    await albumService
      .getByKey(``)
      .then((response) => {
        items = albumService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllAlbums(items));
        }
      })
      .catch(() => {});
    //}
    return items;
  }

  async getAllArtists(user: any, dispatch?: any) {
    let items: any[] = [];

    //if (!isEmpty(user)) {
    await artistService
      .getByKey(``)
      .then((response) => {
        items = artistService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllArtists(items));
        }
      })
      .catch(() => {});
    //}
    return items;
  }
}
export default new SongHelper();
