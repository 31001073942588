import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import artistReducer from "./artistReducer";
import albumReducer from "./albumReducer";
import songReducer from "./songReducer";
import categoryReducer from "./categoryReducer";
const store = configureStore({
  reducer: {
    user: userReducer,
    artist: artistReducer,
    album: albumReducer,
    song: songReducer,
    category: categoryReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
